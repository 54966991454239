import React, { useState } from "react";
import { AlertCircle, CheckCircle2 } from "lucide-react";
import { Helmet } from "react-helmet-async";
import { Carousel } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  FaRegHandshake,
  FaLightbulb,
  FaChalkboardTeacher,
  FaStar,
  FaFemale,
  FaBriefcase,
  FaPaintBrush,
  FaUsers,
} from "react-icons/fa";

export default function ShaktiSangam() {
  const districtsMP = [
    "Agar Malwa",
    "Alirajpur",
    "Anuppur",
    "Ashok Nagar",
    "Balaghat",
    "Barwani",
    "Betul",
    "Bhopal",
    "Burhanpur",
    "Chhatarpur",
    "Chhindwara",
    "Damoh",
    "Datia",
    "Dewas",
    "Dhar",
    "Dindori",
    "Guna",
    "Gwalior",
    "Harda",
    "Hoshangabad",
    "Indore",
    "Jabalpur",
    "Jhabua",
    "Katni",
    "Khandwa",
    "Khargone",
    "Mandla",
    "Mandsaur",
    "Morena",
    "Narsinghpur",
    "Neemuch",
    "Panchmahals",
    "Panna",
    "Rewa",
    "Rajgarh",
    "Ratlam",
    "Rewa",
    "Sagar",
    "Satna",
    "Sehore",
    "Seoni",
    "Shahdol",
    "Shivpuri",
    "Singrauli",
    "Tikamgarh",
    "Ujjain",
    "Umaria",
    "Vidisha",
  ];
  const logos = [
    { src: "./../assets/images/Slider/1.jpg", alt: "Startup Republic" },
    { src: "./../assets/images/Slider/2.jpg", alt: "Im Global" },
    {
      src: "./../assets/images/Slider/3.jpg",
      alt: "Jabalpur Incubation Center",
    },
    { src: "./../assets/images/Slider/4.jpg", alt: "Radio Mirchi" },

    // Add more logos as needed
  ];
  const events = [
    {
      time: "11:00 AM",
      description:
        "Registration of Participants & Time to announce your Presence",
    },
    {
      time: "11:30 AM",
      description: "Arrival of guests, Enablers and Other Dignitaries",
    },
    {
      time: "11:45 AM",
      description: "Setting up day's agenda",
    },
    {
      time: "12:00 PM",
      description: "Lightening of Lamp",
    },
    {
      time: "12:15 PM",
      description: "Felicitation of Guests & Enablers",
    },
    {
      time: "12:20 PM",
      description: "Keynote address by the Chief Guest",
    },
    {
      time: "12:30 PM",
      description: "Enablers session",
    },
    {
      time: "01:00 PM",
      description: "Panel Discussion",
    },
    {
      time: "01:30 PM",
      description: "Session on Women's Health",
    },
    {
      time: "01:40 PM",
      description: "Session on Work life Balance",
    },
    {
      time: "01:50 PM",
      description: "Words of Gratitude and Way Forward",
    },
    {
      time: "02:00 PM",
      description: "Networking Lunch",
    },
  ];
  const leaders = [
    {
      name: "Mrs. Sadhna Singh",
      designation: "Chief Technology Officer",
      photo: "./../assets/images/Guest/1.png",
    },
    {
      name: "Divyanka Tripathi",
      designation: "VP of Engineering",
      photo: "./../assets/images/Guest/2.png",
    },
    {
      name: "Renuka Shahne",
      designation: "Head of Research",
      photo: "./../assets/images/Guest/3.png",
    },
    {
      name: "Neha Tiwari",
      designation: "Director of Innovation",
      photo: "./../assets/images/Guest/4.png",
    },
    {
      name: "Dr. Leela Joshi",
      designation: "Principal Scientist",
      photo: "./../assets/images/Guest/5.png",
    },
    {
      name: "Kanika Tekriwal",
      designation: "Lead Product Architect",
      photo: "./../assets/images/Guest/6.png",
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    city: "",
    email: "",
    mobile: "",
    occupation: "",
    reason: "",
    district: "", // Add this field if you're using it
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const occupationOptions = [
    "Student",
    "Entrepreneur",
    "Corporate Professional",
    "Home Maker",
    "Other",
  ];

  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    // Email validation using regular expression
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address";
    }

    // Mobile validation using regular expression (example for 10-digit numbers)
    const mobileRegex = /^[0-9]{10}$/;
    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile is required";
    } else if (!mobileRegex.test(formData.mobile.trim())) {
      newErrors.mobile = "Please enter a valid 10-digit mobile number";
    }

    if (!formData.district.trim()) {
      newErrors.district = "City is required";
    }

    if (!formData.occupation) {
      newErrors.occupation = "Please select your occupation";
    }

    if (!formData.reason.trim()) {
      newErrors.reason = "Please share your reason for joining";
    } else if (formData.reason.trim().length < 20) {
      newErrors.reason =
        "Please provide a more detailed reason (minimum 20 characters)";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear field error on input change
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setErrors({}); // Clear previous errors

      try {
        const response = await fetch(
          "https://app.incubationmasters.com:5000/api/shaktiSangam/register",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (response.ok) {
          setSubmitted(true);
          console.log(data.message);

          // ✅ Show SweetAlert Success Message
          Swal.fire({
            title: "🎉 Registration Successful!",
            text: "Thank you for registering for Shakti Sangam 2025.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#4CAF50",
          });
        } else {
          console.error(data.message);

          // ✅ Show SweetAlert ONLY for Duplicate Entry Error
          if (
            data.message.includes(
              "User with this Email or Phone already exists"
            )
          ) {
            Swal.fire({
              title: "⚠️ Duplicate Entry!",
              text: "A user with this Email or Phone already exists!",
              icon: "warning",
              confirmButtonText: "OK",
              confirmButtonColor: "#FFA500",
            });
          } else {
            // ✅ Generic Failure Message (No specific server error details)
            Swal.fire({
              title: "Registration Failed",
              text: "Something went wrong. Please contact +91 8319056237.",
              icon: "error",
              confirmButtonText: "Try Again",
              confirmButtonColor: "#FF0000",
            });
          }
        }
      } catch (error) {
        console.error("Error:", error);

        // ✅ Show SweetAlert for Network Error
        Swal.fire({
          title: "🌐 Network Error",
          text: "Unable to submit the form. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#FF0000",
        });
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Shakti Sangam 2025 | The Biggest Women's Day Event in Madhya Pradesh
        </title>

        <meta
          name="description"
          content="Shakti Sangam 2025 | The Biggest Women's Day Event in Madhya Pradesh - Celebrating the power, achievements, and spirit of women with inspiring talks, cultural performances, and networking opportunities."
        />

        <meta
          name="keywords"
          content="Shakti Sangam 2025
Women's Day Celebration
Women in Madhya Pradesh
Bhopal Women's Day Event
International Women's Day 
Shakti Sangam Event"
        />
      </Helmet>
      <div>
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 h-100">
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <h1>SHAKTI SANGAM 2025</h1>
              <h4>Registration has been closed</h4>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
