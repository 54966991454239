import React, { useState, useEffect } from "react";

const UserTracking = () => {
  const [logs, setLogs] = useState([]);
  const [ipAddress, setIpAddress] = useState("Fetching...");

  // Fetch User's IP Address
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const res = await fetch("https://api64.ipify.org?format=json");
        const data = await res.json();
        setIpAddress(data.ip);
        addLog(`User visited the page (IP: ${data.ip})`);
      } catch (error) {
        console.error("Error fetching IP:", error);
      }
    };

    fetchIP();
  }, []);

  // Function to add logs
  const addLog = (event) => {
    const timestamp = new Date().toLocaleString();
    const logEntry = `[${timestamp}] (IP: ${ipAddress}) ${event}`;

    setLogs((prevLogs) => [...prevLogs, logEntry]);
    localStorage.setItem("userLogs", JSON.stringify([...logs, logEntry]));
  };

  // Function to download logs
  const downloadLogs = () => {
    const logContent = logs.join("\n");
    const blob = new Blob([logContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "user_tracking_logs.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-4 bg-dark">
      <h2 className="text-xl font-bold">User Activity Tracker</h2>
      <p className="text-dark-600">User IP: {ipAddress}</p>
      <button
        className="bg-green-500 text-white px-4 py-2 rounded mt-2 ml-2"
        onClick={downloadLogs}
        disabled={logs.length === 0}
      >
        Download Activity Logs
      </button>

      <div className="mt-4 bg-dark">
        <h3 className="text-lg font-semibold">Activity Logs:</h3>
        <div className="bg-gray-100 p-2 bg-dark rounded h-40 overflow-auto">
          {logs.length > 0 ? (
            logs.map((log, index) => <p key={index}>{log}</p>)
          ) : (
            <p>No logs yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserTracking;
